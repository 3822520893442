<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des plaidoyers">
      <template v-slot:button>
        <button
          type="button"
          class="content-wrapper__header-button"
          @click="createAdvocacy()"
        >
          Ajouter un plaidoyer
        </button>
      </template>
      <block-public-advocacies-table @updateAdvocacy="editAdvocacy($event)"></block-public-advocacies-table>
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-news-form :article="modalAdvocacy" @modalClose="closeModal()" :isAdvocacy="true"></block-news-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockPublicAdvocaciesTable from "@/components/blocks/tables/BlockPublicAdvocaciesTable.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockNewsForm from "@/components/blocks/forms/BlockNewsForm.vue";
import { ref } from "vue";


// Modal code
const isModalOpen = ref(false);
const isEditMode = ref(false);
const modalAdvocacy = ref({});
const closeModal = () => {
  isModalOpen.value = false;
};
// Create user function
const createAdvocacy = () => {
  isModalOpen.value = true;
  modalAdvocacy.value = {};
};
// Edit user function
const editAdvocacy = (advocacy) => {
  modalAdvocacy.value = advocacy;
  isModalOpen.value = true;
};
</script>

<style lang="scss" scoped></style>
