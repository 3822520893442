<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="news-table">
            <thead class="news-table__header">
              <tr>
                <th scope="col" class="news-table__header-col">Titre</th>
                <th scope="col" class="news-table__header-col">Description</th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Modifier</span>
                </th>
              </tr>
            </thead>
            <tbody class="news-table__body">
              <tr
                class="news-table__row"
                v-for="article in news"
                :key="article.id"
              >
                <td class="news-table__row-cel">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full object-cover"
                        :src="imageUrl(article.background.path)"
                        :alt="article.title"
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ article.title }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ article.postDate }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="news-table__row-cel news-table__row-cel--fixed">
                  <div class="text-sm text-gray-900">
                    <span>{{article.summary}}</span>
                  </div>
                </td>
                <td class="news-table__row-cel text-right text-sm font-medium">
                  <a @click.prevent="$emit('updateAdvocacy',article)" href="#" class="text-primary hover:text-primary/90 mr-2 cursor-pointer"
                    >Modifier</a
                  >
                  <a @click.prevent="deleteArticle(article.id)" class="text-red-500 hover:text-red-400 cursor-pointer"
                    >Supprimer</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <molecule-pagination 
      v-if="pageInfos" 
      @pageChange="getPageData($event)" 
      :pageInfos="pageInfos"
      :currentPage="currentPage"
    ></molecule-pagination>
  </div>
</template>

<script setup>
import { computed, toRefs, onBeforeMount, ref, inject } from "vue";
import MoleculePagination from "@/components/molecules/MoleculePagination.vue";
import {useStore} from "vuex";
const {state, dispatch} = useStore();
const news = computed( () => state.news.publicAdvocacies?.data);
const pageInfos = computed( () => state.news.publicAdvocacies?.meta);
const currentPage = ref(1);
const fetchAdvocacies = async () => {
  await dispatch('news/getAll', {
    page: currentPage.value,
    filter:'filter.isAdvocacy=true'
  });
}
const getPageData = async (page) => {
  currentPage.value = page;
  await fetchAdvocacies();
}
onBeforeMount( async () => {
  await fetchAdvocacies();
});
const deleteArticle =  async (articleId) => {
  try {
    await dispatch('news/delete', {
      id: articleId,
      isAdvocacy: true
    });
  } catch (err) {
    console.log(err);
  }
}
const emit = defineEmits(['updateNew']);
const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
.news-table {
  @apply min-w-full divide-y divide-gray-200 table-fixed;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &--fixed{
        @apply min-w-[250px] 
        whitespace-normal;
      }
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
  }
}
</style>
